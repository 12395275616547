/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=3027733c";
import graphql from "/node_modules/.strapi/vite/deps/@strapi_plugin-graphql_strapi-admin.js?v=3027733c";
import usersPermissions from "/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=3027733c";
import { renderAdmin } from "/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=3027733c";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    "strapi-cloud": strapiCloud,
    graphql: graphql,
    "users-permissions": usersPermissions,
  },
});
